<template>
  <v-row>
    <v-col
      ><v-select
        dense
        :label="$t('model.name.institution')"
        :placeholder="$t('model.name.institution')"
        class="white"
        :items="institution"
        :item-text="setName"
        outlined
        v-model="item"
        item-value="groupCode"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect"],
  data: () => ({
    institution:[],
    id: null,
    item: '',
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  watch: {
    item: function (val) {
      this.item = val
      this.callbackSelect(val);
    },
  },
  created() {
    this.api.method = "get";
    this.api.url =
      this.$api.servers.analysis +
      "/api/v1/" +
      this.$_getLocale() +
      "/moderator/dailyGameProgress/institution";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.institution = resp.Institution;
    //  this.institution.sort((a, b) => (a.name < b.name ? 1 : -1));
      this.institution.push({
        groupCode: null,
        id: 0,
        name: this.$t("model.filterBy.all"),
        countPlayer: this.$_.sumBy(this.institution, 'countPlayer')
      });
      this.institution.reverse();
      this.item = null
    };
  },
  methods:{
    setName: (item) => `${item.name} (${item.countPlayer})`,
  },
  mounted() {
    this.$api.fetch(this.api);
  },
};
</script>

<style>
</style>