<template>
  <div>
    <v-row class="mb-3">
      <v-col><h3>Total Questions</h3></v-col>
    </v-row>
     <!-- BOC:[loader] -->
     <ALoader :isLoading="logApi.isLoading"></ALoader>
      <!-- EOC -->
    <div  v-if="!logApi.isLoading" >
       <!-- BOC:[header] -->
   
    <!-- EOC -->
      <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">
            School Year
          </th>
          <th class="text-right">
           Count
          </th>
        </tr>
      </thead>
      <tbody>
        <template>
          <tr v-for="(d, i) in questions" :key="i">
            <td>
              Year: {{ d.schoolYear }}
            </td>
            <td class="text-right">
              {{d.count }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-black">
              Total: 
            </td>
            <td class="text-right font-weight-black">
             {{ $_.sumBy(questions, 'count') }}
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
    </div>
    <v-row class="my-3">
      <v-col><h3>Last 30 Days Report</h3></v-col>
    </v-row>
    <FilterInstitution :callbackSelect="selectSchool"></FilterInstitution>
      <!-- BOC:[loader] -->
      <ALoader :isLoading="api.isLoading"></ALoader>
      <!-- EOC -->
    <div  v-if="!api.isLoading" >
       <!-- BOC:[header] -->
   
    <!-- EOC -->
      <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">
            School Year
          </th>
          <th class="text-right">
           Count
          </th>
        </tr>
      </thead>
      <tbody>
        <template>
          <tr v-for="(d, i) in schoolYear" :key="i">
            <td>
              Year: {{ d.year }}
            </td>
            <td class="text-right">
              {{d.count }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-black">
              Total: 
            </td>
            <td class="text-right font-weight-black">
             {{ $_.sumBy(schoolYear, 'count') }}
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
    </div>
    <div class="mt-3">
    <apexcharts
    v-if="!api.isLoading"
    max-height="600px"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexcharts>
  </div>
  </div>

</template>

<script>
import VueApexCharts from "vue-apexcharts";
import FilterInstitution from '../components/Moderator/DailyGame/FilterInstitution.vue';
export default {
  name: "Chart",
  components: {
    apexcharts: VueApexCharts,
    FilterInstitution,
  },
  data: function () {
    return {
      show: false,
      schoolYear:[],
      api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    logApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    date: [],
    questions:[],
      chartOptions: {
        chart: {
          id: "basic-bar",
          toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
        enabled: true,
      },
      colors: ["#008FFB"],
      title: {
        text: "Players count by day",
      },
      },
      series: [
        {
          name: "Count",
          data: [],
        },
      ],
    };
  },
  created(){
    this.api.method = "GET";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.date = resp.Date;
      this.schoolYear = resp.SchoolYear
      this.process();
    };

    this.logApi.method = "GET";
    this.logApi.url = `${this.$api.servers.log}/api/v1/en/dailyGameProgress/questionBySchoolYear`;
    this.logApi.callbackReset = () => {
      this.logApi.isLoading = true;
      this.logApi.isError = false;
    };

    this.logApi.callbackError = (e) => {
      this.logApi.isLoading = false;
      this.logApi.isError = true;
      this.logApi.error = e;
    };

    this.logApi.callbackSuccess = (resp) => {
      this.questions = resp;
      this.logApi.isLoading = false;
    };
  },
 mounted(){
  this.$api.fetch(this.logApi);
 },
  methods: {
    selectSchool(val){
      if(val == null){
        this.api.url = `${this.$api.servers.analysis}/api/v1/en/moderator/dailyGameProgress/analysis`;
      }else{
        this.api.url = `${this.$api.servers.analysis}/api/v1/en/moderator/dailyGameProgress/analysis?groupCode=${val}`;
      }
      this.date = []
      this.series[0].data = []
      this.chartOptions.xaxis.categories = []
     
      this.$api.fetch(this.api);
    },
    process() {
  
  // const uniqueDates = this.$_.uniq(date.map(item => item.lastPlayed));
  
  // // Sort the unique dates
  // const sortedDates = this.$_.sortBy(uniqueDates);
  
  // Get the last 30 days from today
  const last30Days = [];
  for (let i = 29; i >= 0; i--) {
    last30Days.push(this.$moment().subtract(i, 'days').format('YYYY-MM-DD'));
  }
  
  // Concatenate the last 30 days with the sorted unique dates
  const xCategories = [...last30Days];
  
  this.chartOptions.xaxis.categories = xCategories
  // Prepare series data
  const seriesData = Array.from({ length: xCategories.length }, () => 0);
  
  this.date.forEach(item => {
    const index = xCategories.indexOf(item.lastPlayed);
    if (index !== -1) {
      const counts = item.data.map(d => d.count);
      seriesData.splice(index, 1, this.$_.sum(counts));
    }
  });
  
  // Calculate the total count
  // const totalCount = this.$_.sum(seriesData);
  
  // Create the final series
  
  
  this.series[0].data = seriesData
  this.api.isLoading = false
    },
    
  },
};
</script>
