<template>
  <div>
    <v-row class="mb-3">
      <v-col><h3>Floor Distribution</h3></v-col>
    </v-row>
    <!-- BOC:[loader] -->
    <ALoader :isLoading="api.isLoading"></ALoader>
    <!-- EOC -->
    <div v-if="!api.isLoading">
      <!-- BOC:[header] -->
    </div>
    <div class="mt-3">
      {{ series.data }}
      <apexcharts
      v-if="!api.isLoading"
        type="scatter"
        height="650"
        :options="chartOptions"
        :series="series"
      ></apexcharts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "Chart",
  components: {
    apexcharts: VueApexCharts,
  },
  data: function () {
    return {
      show: false,
      schoolYear: [],
      api: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      data: [],
      series: [
        {
          name: "Count",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          id: "basic-bar",
          toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        },
        xaxis: {
          title: {
          text: "Floor number",
      },
        },
        yaxis: {
          title: {
          text: "Total",
      },
        },
      },
    };
  },

  created() {
    this.api.method = "GET";
    this.api.url = `${this.$api.servers.game}/api/v1/en/moderator/dailyGameProgress/countByFloor`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.data = resp;
      this.process();
    };
  },
mounted(){
  this.$api.fetch(this.api);
},
  methods: {
 
    process() {
      const convertedData = this.$_.map(this.data, floor => [floor.currentFloor,floor.total]);
     this.series[0].data = convertedData
      this.api.isLoading = false;
    },
  },
};
</script>
