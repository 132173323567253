<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col
        ><h1>{{ $t("model.name.dailyGameProgress") }}</h1></v-col
      >
    </v-row>
    <!-- EOC -->
     <!-- BOC:[tabs] -->
     <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab v-for="item in tabs" :key="item.key" :href="`#${item.key}`">
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <!-- EOC -->
    <!-- BOC:[tab] -->
    <v-tabs-items v-model="tab">
      <!-- BOC:[model] -->
      <v-tab-item key="all" value="all" class="px-1 py-2">

    <!-- BOC:[table] -->
    <BreadBrowseTable
      role="Moderator"
      :model="model"
      :url="`${$api.servers.game}/api/v1/en/moderator/dailyGameProgress`"
      :isSearchable="true"
    ></BreadBrowseTable>
    <!-- EOC -->
  </v-tab-item>
  <v-tab-item key="analysis" value="analysis" class="px-1 py-2">

<!-- BOC:[table] -->
<TabModeratorDailyGameProgressAnalysis
 
></TabModeratorDailyGameProgressAnalysis>
<!-- EOC -->
</v-tab-item>
<v-tab-item key="floorDistribution" value="floorDistribution" class="px-1 py-2">

<!-- BOC:[table] -->
<TabModeratorDailyGameProgressFloorDistribution
 
></TabModeratorDailyGameProgressFloorDistribution>
<!-- EOC -->
</v-tab-item>
      <!-- EOC -->
    </v-tabs-items>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import model from "@/models/items/dailyGameProgress";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTableV2";
import TabModeratorDailyGameProgressAnalysis from "@/tabs/TabModeratorDailyGameProgressAnalysis";
import TabModeratorDailyGameProgressFloorDistribution from "@/tabs/TabModeratorDailyGameProgressFloorDistribution";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    TabModeratorDailyGameProgressAnalysis,
    TabModeratorDailyGameProgressFloorDistribution
    //EOC
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: [
    //
  ],
  data: () => ({
   //BOC:[tabs]
   tab: null,
    tabs: [
      {
        key: "all",
        label: "Players",
      },
      {
        key: "analysis",
        label: "Analysis",
      },
      {
        key: "floorDistribution",
        label: "Floor Distribution ",
      },
    ],
    //EOC
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[model]
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: 'Daily Game Progress',
      to: 'dailyGameProgress',
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>